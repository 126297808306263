<template>
  <input
    :name="name"
    :value="value"
    :placeholder="placeholder"
    :class="{ invalid: invalid, valid: valid }"
    :required="required"
    type="text"
    @change="onChange"
    @keyup="onKeyUp"
    @keypress.enter="onEnter"
  >
</template>
<script lang="ts">
import Vue from 'vue'

import BaseInput from '@/components/atoms/BaseInput.vue'

export default Vue.extend({
  extends: BaseInput
})

</script>
