<script lang="ts">
/**
 * @module InputMixin
 *
 * [InputMixin] enables data validation rules on [Inputs]
 *
 * @rules {array} are the specific rules to be run on the [Input]
 * @invalid {boolean} contains whether or not the [Input] is failing or passing validation
 *
 * ==========================================
 */

import { mask } from 'vue-the-mask'
import Vue from 'vue'

export default Vue.extend({
  directives: {
    mask: mask,
    rules: function (input, binding, vnode) {
      if (typeof binding.value === 'function') {
        // @ts-ignore
        vnode.context.rules[binding.expression] = true
      } else if (typeof binding.value === 'object') {
        // @ts-ignore
        vnode.context.rules = binding.value
      }
    }
  },
  data: function () {
    return {
      /*
                 * @rules {object} contains an array of
                 * specific rules to be run on the [Input]
                 */
      rules: {},
      /*
                * @invalid {boolean} contains whether or not
                * the [Input] value is actively failing validation
                */
      invalid: false,
      /*
                 * @valid {boolean} contains whether or not
                 * the [Input]v value is currently passing validation
                 */
      valid: false
    }
  },
  created () {
    if (this.required) {
      this.rules.notEmpty = true
    }
  },
  methods: {
    onEnter (event) {
      // PREVENT DEFAULT BROWSER FORM SUBMISSION
      if(event.stopPropagation){
        event.stopPropagation();
      }
      if(event.preventDefault){
        event.preventDefault();
      }
      this.onChange(event)
      Vue.nextTick(() => {
        this.$emit('enter', event, this)
      })
    },
    onChange (event) {
      // If target is checkbox, value boolean
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
      this.setValue(value)
    },
    onKeyUp (event) {
      let value
      if (event.target.tagName === 'DIV') {
        // Contenteditable DIV
        value = event.target.textContent
      } else {
        value = event.target.value
      }
      this.$emit('keyup', value, this)
      if(this.changeOnKeyUp){
        this.onChange(event)
      }
    },
    setValue (value) {
      // If rules are defined for this field then run validation
      if (this.rules) {
        this.invalid = false
        this.validate(value)
      }
      this.$emit('change', value, this)
    },
    validate (value) {
      value = value !== undefined ? value : this.value || ''

      // Loop over all rules for given field
      for (const ruleName in this.rules) {
        // Check to see if the rule exists

        if (Object.prototype.hasOwnProperty.call(this, ruleName) && typeof this[ruleName] === 'function') {
          const ruleOptions = this.rules[ruleName]
          // Run the rule
          if (!this[ruleName](value, ruleOptions)) {
            this.invalidate(ruleName)
          } else {
            this.valid = true
          }
        } else {
          // eslint-disable-next-line
          console.error(`ReferenceError: Form validation rule ${ruleName} is referenced but not defined`);
        }
      }
    },
    invalidate (ruleName) {
      this.invalid = true
      this.valid = false
      this.$emit('error', {
        target: this,
        error: ruleName
      })
    },
    notEmpty (value) {
      return !!value
    }
  }
})
</script>
