<template>
  <main>
    <div class="above-the-fold">
      <br />
      <br />
      <TheDesktopBetaInviteHeroSection />
    </div>
  </main>
</template>

<script lang="ts">
import TheDesktopBetaInviteHeroSection from "@/components/organisms/TheDesktopBetaInviteHeroSection.vue";


export default {
  components: {
    TheDesktopBetaInviteHeroSection
  }
}
</script>

<style scoped lang="less">
  @import '../less/variables';
  main {
    background: #141627;
  }
  .above-the-fold{
    background: url(/img/backgrounds/gradient-background.jpg);
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
  }


  video{
    width: 100%;
    outline: 0;
    text-align: center;
  }

  /deep/ .app-width{
    width: 100%;
    margin-left:auto;
    margin-right:auto;
    box-sizing: border-box;
    padding-left: 16pt;
    padding-right: 16pt;
  }
  @media(min-width: @firstbreakpoint){
    /deep/ .app-width{
      width: 600px;
      padding-left: 0pt;
      padding-right: 0pt;
    }
  }
  @media(min-width: @secondbreakpoint){
    /deep/ .app-width{
      width: 800px;
    }
  }
  @media(min-width: @thirdbreakpoint){
    /deep/ .app-width{
      width: 1000px;
    }
  }
  @media(min-width: @fourthbreakpoint){
    /deep/ .app-width{
      width: 1300px;
    }
  }
</style>
