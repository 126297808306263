<script lang="ts">
import InputMixin from '@/components/MixinInput.vue'
import Vue from 'vue'


export default Vue.extend({
  mixins: [
    InputMixin
  ],
  props: {
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Boolean, Number, Object, Array, null],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    changeOnKeyUp: {
      type: Boolean,
      default: false
    }
  }
})

</script>

<style lang="less" scoped>

@import '../../less/variables';

::placeholder {
  color: @grayMedium;
  opacity: 1; /* Firefox */
}

input,
select,
textarea,
.v-select,
.highlightable-input{
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  transition: all 500ms;
  border: 0;
}

label {
  font-weight: 300;
}

input,
textarea,
/deep/ .v-select,
.highlightable-input{
  display: block;
  width: 100%;
  font-size: inherit;
  padding: 1em;
  border-radius: 5px;
  font-weight: 500;
  outline: none;
  background-color: white;
  box-sizing: border-box;
  transition: border 500ms;
  color: @grayDark;
  font-family: inherit;
}

select {
  background-color: white;
  border: none;
  border-radius: 7px;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  padding: 1rem;
  text-align: center;
  text-align-last: center;
  width: 100%;
}
textarea{
  resize:vertical;
}

input.invalid,
select.invalid,
textarea.invalid,
.v-select.invalid{
  box-shadow: 0 0 5px fade(@red, 50%);
}

input.valid, select.valid, .v-select.valid{
  position: relative;
  &::after{
    content:'';
    width:10px;
    height:10px;
    background:#ff0000;
    top:100%;
    left:50%;
    margin-left:5px;
    display: block
  }
}

.input-search{
  position: relative;
  input {
    padding: 0.75em 1em 0.75em 2.5em;
  }
  .icon{
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    left: 1em;
    color: @grayMedium;
    &.x{
      left: auto;
      right: 1em;
      cursor: pointer;
      transform: scale(1.2);
      &:hover{
        color: @black;
      }
    }
  }
}

.highlightable-input{
  overflow-wrap: anywhere;
  background: transparent;
  outline: 0;
  cursor: text;
  width: 100%;
  color: @black;
  &.small{
    font-size: 1.1rem;
  }
  /deep/ span {
    color: @purpleVibrant;
  }
  /deep/ div + div{
    height: 1em;
  }
}
</style>
