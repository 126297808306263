<template>
  <div>
    <div v-if="requested && !invitedFriend && !showInviteFriendForm" class="form-success">
      <h2>Your request was sent.</h2>
    </div>
    <div class="invite-form" v-if="requested && !invitedFriend && !showInviteFriendForm">
      <Button class="special uppercase" label="Share with a Friend" @click="showInviteFriendForm = true" />
    </div>
    <div v-if="invitedFriend" class="form-success">
      <h2>Thanks, {{name}}!<br /><br />We'll be in touch soon.</h2>
    </div>
    <div class="invite-form" v-if="showInviteForm">
      <p class="error" v-if="error">{{error}}</p>
      <InputText v-if="showInviteFriendForm" placeholder="Your Name" @change="name=$event;" :value="name" :required="true" ref="inputName"/>
      <InputText v-if="showInviteFriendForm" placeholder="Your Friends Name" @change="friendName=$event;" :value="friendName" :required="true" ref="inputFriendName"/>
      <InputEmail :placeholder="emailPlaceholder" @change="email=$event;" :value="email" :required="true" ref="inputEmail"/>
      <Button class="special uppercase" :label="buttonLabel" @click="validateAndSubmit" />
    </div>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import InputEmail from "@/components/atoms/InputEmail.vue";
  import InputText from "@/components/atoms/InputText.vue";
  import Button from "@/components/atoms/Button.vue";
  import axios from 'axios';

  export default Vue.extend({
    props: {
      initialFormData: {
        type: Object
      }
    },
    data(){
      return {
        email: "",
        name: "",
        friendName: "",
        error: "",
        inviter: "",
        requested: false,
        invitedFriend: false,
        showInviteFriendForm: false
      }
    },
    components: {
      Button,
      InputEmail,
      InputText
    },
    computed:{
      emailPlaceholder(){ return this.requested ? 'Your Friends Email' : 'Your Email'},
      buttonLabel(){ return this.requested ? 'Share with a Friend' : 'Request Your Invite' },
      showInviteForm(){
        return !this.requested || (this.showInviteFriendForm && !this.invitedFriend)
      }
    },
    watch: {
      initialFormData(){
        this.initialize()
      }
    },
    mounted(){
      this.initialize();
    },
    methods: {
      initialize(){
        if(this.initialFormData){
          this.requested = true;
          this.showInviteFriendForm = true;
          if(this.initialFormData.email){
            this.inviter = this.initialFormData.email;
          }
          if(this.initialFormData.name){
            this.name = this.initialFormData.name;
          }
        }
      },
      async validate(){
        this.error = null;
        if(this.email && this.$refs.inputEmail.$el.classList.contains('valid')){
          if(this.showInviteFriendForm){
            if(this.friendName && this.$refs.inputFriendName.$el.classList.contains('valid')){
              if(this.name && this.$refs.inputName.$el.classList.contains('valid')){
                return true
              }else{
                this.error = 'Enter your name';
                return false;
              }
            }else{
              this.error = 'Enter your friends name';
              return false;
            }
          }
          return true
        }else{
          this.error = 'Enter a valid email address';
          return false;
        }
      },
      async validateAndSubmit(){
        if(await this.validate()){
          console.log('Requesting invite', this.email);
          let payload = {
            email: this.email
          };
          if(this.inviter){
            payload['inviter'] = this.inviter;
            payload['name'] = this.name;
            payload['friendName'] = this.friendName;
          }else{
            this.$store.commit('user/SET', {email: this.email});
          }
          axios
            .post('/api/invites', payload)
            .then(response => {
              if(response && response.data._id){
                window.fbq('track', 'Lead');
                dataLayer.push({event: 'lead' });
                if(!this.requested){
                  this.requested = true;
                  this.inviter = this.email;
                  this.email = '';
                }else if(!this.invitedFriend){
                  this.invitedFriend = true;
                }
              }else if(response.data.message){
                this.error = response.data.message;
              }else{
                this.error = 'Unknown error';
              }
            })
            .catch(e => {
              console.log(e);
            })
        }
      }
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';


  .invite-form{
    margin:0 auto;
    input, button{
      padding: 0.9rem 1.5rem;
      font: inherit;
      height: 45px;
      box-sizing: border-box;
      clear: both;
      width: 100%;
      margin: 0;
      border-radius: 6px;
      border: none;
      text-align: center;
      outline: none;
    }
    input{
      font-size: 1.2rem;
      margin-bottom: 0.75rem;
    }
    button{
      font-weight: 600;
    }
  }

  p.error {
    color: #fc7a7a;
  }
  p.green {
    color: #b3fc96;
  }

  .form-success{
    margin:0 auto;
    border-radius: 0.5rem;
    padding: 2rem 0;
    h2{
      margin-top: 0;
    }
    p{
      margin-bottom: 0;
    }
  }



  @media(min-width: @firstbreakpoint){
    button{
      width: auto;
      margin: 0 0.75rem;
      display: inline-block;
    }
    h3{
      margin-bottom: 2em;
    }
    .invite-form, .form-success{
      max-width: 400px;
    }
  }
  @media(min-width: @secondbreakpoint){
  }
  @media(min-width: @thirdbreakpoint){
    .invite-form{
      margin: 4rem auto;
      input{
        height: 60px;
        font-size: 1.5rem;
        margin-bottom: 1.25rem;
      }
      button {
        height: 60px;
        font-size: 1.2rem;
      }

    }
    .invite-form, .form-success{
      max-width: 600px;
    }
  }
</style>
