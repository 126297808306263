<template>
  <div>
    <div class="app-width embed-container-container">
      <div class='embed-container'>
        <iframe src="https://player.vimeo.com/video/537096945?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="StoryFolder Desktop Beta - Thanks for requesting your invite."></iframe>
      </div>
    </div>
    <div class="success app-width" v-if="success">
      <h1>
        Thanks for requesting<br />an invite to the beta<span v-if="name">, {{name}}</span>!
      </h1>
      <h3>
        <br />
        I have your email as <span class="email">{{email}}</span>.<br />
        If approved, I'll be emailing you there.
      </h3>
      <div class="benefits">
        <div class="benefit">
          <title>
            Pre-Order Discount
          </title>
        </div>
      </div>
    </div>
    <h2>The first 100 beta users get<br /><strong>50% off forever</strong> and early access.</h2>
    <h2 v-if="success">Get extra brownie points for sharing with a friend.</h2>
    <FormRequestInvite :initial-form-data="initialFormData"/>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import axios from 'axios';
  import FormRequestInvite from "@/components/organisms/FormRequestInvite.vue";

  export default Vue.extend({
    components: {
      FormRequestInvite
    },
    data(){
      return {
        success: false
      }
    },
    computed:{
      email(){
        return this.$store.state.user.user.email
      },
      name(){
        return this.$store.state.user.user.name
      },
      initialFormData(){
        if(this.email){
          return {
            email: this.email
          }
        }
        return false
      }
    },
    watch:{
      email: {
        immediate: true,
        handler(){
          if(this.email){
            this.requestInvite()
          }
        }
      }
    },
    methods: {
      requestInvite(){
        axios
          .post('/api/invites', { email: this.email })
          .then(response => {
            console.log(response.data);
            if(response && response.data._id){
              this.success = true;
            }
          })
          .catch(e => {
            console.log(e);
          })
      }
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';
  .embed-container-container{
    position: relative;
    max-width: 800px;
    overflow: hidden;
    padding-bottom: 2rem;
  }
  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    border-radius: 0.5rem;
  }
  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .success{
    padding: 3em 1em;
    text-align: center;
    border:2px solid fade(#ffffff, 10%);
    border-radius: 0.5rem;
    max-width: 800px;
  }
  h3{
    font-size: 1rem;
  }
  h2{
    font-size: 1.5rem;
  }
  .email{
    color: @white;
  }
  @media(min-width: @firstbreakpoint){
    .success {
      padding: 4em 3em !important;
    }
    h3{
      font-size: 1.5rem;
    }
    h2{
      font-size: 2rem;
    }
  }
</style>
