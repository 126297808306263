<template>
  <div class="featurette">
    <IconSvg v-if="icon" :name="icon" />
    <span v-if="number" class="number">{{number}}</span>
    <div class="title">{{title}}</div>
    <p>{{description}}</p>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import IconSvg from "@/components/atoms/IconSvg.vue";

  export default Vue.extend({
    props: {
      icon: {
        type: String,
        required: false
      },
      number: {
        type: String,
        required: false
      },
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      }
    },
    components: {
      IconSvg
    }
  });
</script>
<style scoped lang="less">
  @import "../../less/variables";
  .featurette{
    display: grid;
    grid-template-columns: 2em auto;
    align-items: center;
    grid-column-gap: 0.5em;
  }
  .title{
    grid-row-start: 1;
    grid-column-start: 2;
    font-weight: 600;
  }
  .icon{
    font-size: 1.5em;
    opacity: 0.3;
  }
  .number{
    font-size: 1.5em;
    font-weight: 600;
    opacity: 0.5;
    text-align: center;
  }
  p{
    grid-column-start: 2;
    grid-row-start: 2;
    margin: 0;
    font-size: inherit;
  }
</style>
