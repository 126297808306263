<template>
  <div class="logo">
    <img
      src="/img/youchat-logo.png"
      class="logo"
    >
    <span>
      YouChat {{ append }}
    </span>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  export default Vue.extend({
    props: {
      showBeta: {
        type: Boolean,
        default: false
      },
      append:{
        type: String,
        default: ''
      }
    }
  });
</script>
<style scoped lang="less">
@import '../../less/variables';
  .logo{
    font-weight: 700;
    font-size: 1em;
    letter-spacing: -0.05em;
    align-items: center;
    gap: 0.25em;
    display: inline-flex;
    color: @black;
    justify-content: center;
    img {
      height: 1.1em;
      vertical-align: middle;
    }
    span.beta{
      font-size: 0.75em;
      text-transform: uppercase;
      font-weight: 300;
      opacity: 0.5;
    }
    &.stack{
      flex-direction: column;
      img{
        top: 0;
      }
    }
  }
</style>
