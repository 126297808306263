<template>
  <section class="hero">
    <div class="app-width">
      <h1>
        <TheStoryFolderLogo />
        <span>For Desktop.</span>
      </h1>
      <h3>
        Join the Desktop Beta Pre-Launch.
      </h3>
      <h3 v-if="false">
        Effortlessly convert videos into storyboards,<span /><b />
        shot lists, clips, images and insights.
      </h3>
      <ModalRequestInviteComplete />
      <p class="os-list">All-new desktop app for <span><IconSvg name="windows" /> Windows</span> and <span><IconSvg name="apple" /> macOS.</span></p>
    </div>
    <img src="/img/storyfolder-desktop-hero.png" class="hero-image" loading="lazy"/>
    <div class="app-width featurettes">
      <Featurette
        icon="arrow"
        title="Everything you loved about the original."
        description="Effortlessly create accurate, shot-for-shot breakdowns of any production in seconds. All with one click." />
      <Featurette
        icon="film"
        title="All-new design."
        description="New interactive views give you the power to discover, customize, and export exactly what you need, easier and faster than ever." />
      <Featurette
        icon="film"
        title="Powerful new features."
        description="Filter and organize your shots. Add your notes, tags and custom metadata. All with the security of a desktop application." />
    </div>
  </section>
</template>
<script lang="ts">
  import Vue from 'vue';
  import TheStoryFolderLogo from "@/components/molecules/TheStoryFolderLogo.vue";
  import Featurette from "@/components/molecules/Featurette.vue";
  import IconSvg from "@/components/atoms/IconSvg.vue";
  import ModalRequestInviteComplete from "@/components/organisms/ModalRequestInviteComplete.vue";

  export default Vue.extend({
    components: {
      IconSvg,
      Featurette,
      TheStoryFolderLogo,
      ModalRequestInviteComplete
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';
  section {
    text-align: center;
    margin-top: 1em;
  }
  h1{
    line-height: 1.25em;
    margin-bottom: 0.8em;
    font-weight: 600;
    span{
      background: -webkit-linear-gradient(45deg, #a7e5e0, #e0b2f5, #fc6169);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

    }
  }
  h3{
    color: fade(@white, 50%);
    font-size: 1.5rem;
  }
  .os-list{
    margin: 2em 0;
    line-height: 2em;
    .icon{
      height: 32px;
      width: 32px;
      zoom: .6;
    }
    span{
      white-space: nowrap;
    }
  }

  button{
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0.9rem 1.5rem;
    letter-spacing: 1px;
    margin: 1rem auto;
    display: block;
  }
  .hero-image{
    width: 100%;
  }

  .featurettes{
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    margin: 2em auto 3em auto !important;
    text-align: left;
    gap: 1em;
  }
  .featurette{
    margin-bottom: 1.5em;
    &:nth-child(5n){
      color: #fca87e;
    }
    &:nth-child(5n+1){
      color: #53c7e4;
    }
    &:nth-child(5n+2){
      color: #ceacf5;
    }
    &:nth-child(5n+3){
      color: #fa9ec5;
    }
    &:nth-child(5n+4){
      color: #fc7a7a;
    }
  }

  @media(min-width: @firstbreakpoint){
    button{
      width: auto;
      margin: 0 0.75rem;
      display: inline-block;
    }
    .hero-image{
      width: 80%;
    }
    h3 span{
      display: block;
    }
    h1{
      margin-top: 0.75em;
      margin-bottom: 0.3em;
    }
    h3{
      margin-bottom: 2em;
    }
  }
  @media(min-width: @secondbreakpoint){
    h1{
      font-size: 55px;
    }
    h3{
      font-size: 25px;
    }
    .featurette{
      font-size: 1.25rem;
    }
    .featurettes{
      width: 70% !important;
    }
  }
  @media(min-width: @thirdbreakpoint){
    h1{
      font-size: 65px;
    }
    .featurettes{
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 3em !important;
    }
  }
  @media(min-width: @fourthbreakpoint){
    .featurette{
      font-size: 1.3rem;
    }
  }
</style>
